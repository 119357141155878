const ETF = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start text-center text-[1.25rem] text-white font-itim">
      <a className="[text-decoration:none] self-stretch bg-darkgoldenrod overflow-hidden flex flex-row items-center justify-between py-[0.625rem] px-[2.187rem] text-[inherit] sm:pl-[0.313rem] sm:pt-[0.625rem] sm:pr-[0.313rem] sm:box-border">
        <a className="[text-decoration:none] relative text-[inherit]">HOME</a>
        <a
          className="[text-decoration:none] relative text-[inherit]"
          href="https://x.com/jeetfonsol"
        >
          TWITTER
        </a>
        <a
          className="[text-decoration:none] relative text-[inherit]"
          href="https://t.me/jeetfonsol"
        >
          TELEGRAM
        </a>
        <a
          className="[text-decoration:none] relative text-[inherit]"
          href="https://pump.fun/J3BdqTszuET5P36dXaQtJHsxz8v6mrZYN1S4uHvNFhM1"
        >
          CHART
        </a>
      </a>
      <div className="w-[91.563rem] bg-darkgoldenrod h-[44.375rem] overflow-hidden shrink-0 flex flex-row flex-wrap items-center justify-between sm:pl-[0.313rem] sm:pt-[0.625rem] sm:pr-[0.313rem] sm:box-border">
        <img
          className="w-[60.5rem] relative h-[43.75rem] object-cover"
          alt=""
          src="/telegramcloudphotosize45945311757004292536y-1@2x.png"
        />
        <img
          className="w-[31rem] relative h-[43.813rem] object-cover"
          alt=""
          src="/image-1@2x.png"
        />
      </div>
      <div className="self-stretch bg-darkgoldenrod overflow-hidden flex flex-row flex-wrap items-center justify-center py-[2.437rem] px-[0.625rem] text-[2.188rem]">
        <div className="flex-1 relative">Sell The News - $JEETF</div>
      </div>
      <div className="self-stretch bg-darkgoldenrod overflow-hidden flex flex-row flex-wrap items-center justify-center py-[0.625rem] px-[6.25rem] gap-[0.625rem_2.187rem] text-left">
        <div className="flex-1 relative inline-block min-w-[25rem]">
          <p className="m-0">
            It's time to set the record straight. Yes, you heard correctly: it's
            time to jeet your ETH ETF, or resign yourself to a life of poverty!
            Yes, you will be poorer than an art student's bank account in April.
          </p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            Imagine a world where you keep clutching your ETH ETF like it's your
            precious one ring, secretly hoping it will take you to the Promised
            Land. Spoiler alert: this is not an epic quest, and Gandalf will not
            come to save you. On the contrary, your wealth will melt faster than
            ice cream in the July sun.
          </p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            So, what to do? The answer is simple: throw away this digital ball
            as quickly as possible! Hear me out, it's a choice between living
            the high life with yachts and private islands or, let's be honest,
            continuing to eat instant noodles until the end of time. Seriously,
            who wants that?
          </p>
        </div>
        <img
          className="w-[44.688rem] relative h-[24.688rem] object-cover"
          alt=""
          src="/galvin-the-monkey-3-1@2x.png"
        />
      </div>
      <div className="self-stretch bg-darkgoldenrod overflow-hidden flex flex-row flex-wrap items-center justify-center py-[2.437rem] px-[0.625rem] text-[2.188rem]">
        <div className="flex-1 relative">BUY ETF BE POOR</div>
      </div>
      <div className="self-stretch bg-darkgoldenrod overflow-hidden flex flex-row flex-wrap items-center justify-center py-[0.625rem] px-[6.25rem] gap-[0.625rem_2.187rem] text-left">
        <img
          className="w-[42.625rem] relative h-[30.375rem] object-cover"
          alt=""
          src="/galvin-the-monkey-2-1@2x.png"
        />
        <div className="flex-1 relative inline-block min-w-[25rem]">
          <ol className="m-0 font-inherit text-inherit pl-[1.35rem]">
            <li>
              Create a Wallet: download phantom or your wallet of choice from
              the app store or google play store for free. desktop users,
              download the google chrome extension by going to phantom.app
            </li>
          </ol>
          <p className="m-0">&nbsp;</p>
          <ol className="m-0 font-inherit text-inherit pl-[1.35rem]">
            <li>
              Get some $SOL: have sol in your wallet to switch to $JEETF. if you
              don’t have any sol, you can buy sol from an exchange or cross
              chain swap and send it to your wallet
            </li>
          </ol>
          <p className="m-0">&nbsp;</p>
          <ol className="m-0 font-inherit text-inherit pl-[1.35rem]">
            <li>
              Go to Raydium, Jupiter, etc.: connect to raydium or jupiter. go
              raydium.io or jup.ag in google chrome or on the browser inside
              your phantom app. connect your wallet. paste the $JEETF token
              address into raydium or jupiter and confirm the swap. when phantom
              prompts you for a wallet signature, sign
            </li>
          </ol>
          <p className="m-0">&nbsp;</p>
          <ol className="m-0 font-inherit text-inherit pl-[1.35rem]">
            <li>{`Swap $SOL for $JEETF: we have zero taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility. `}</li>
          </ol>
        </div>
      </div>
      <div className="self-stretch bg-darkgoldenrod overflow-hidden flex flex-row flex-wrap items-center justify-center p-[0.625rem] text-[2.188rem] text-whitesmoke">
        <div className="flex-1 relative">
          <p className="m-0">
            <span>{`CA `}</span>
            <span className="text-white">:</span>
          </p>
          <p className="m-0 text-black">{` `}</p>
        </div>
      </div>
    </div>
  );
};

export default ETF;
